import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import AOS from 'aos'
import ReactWhatsapp from 'react-whatsapp';
import useWindowDimensions from "./useWindowDimensions";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ExectiveMenu from "./Screen/ExectiveMenu";
import Subscription from "./Screen/Subscription";


import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const { width } = useWindowDimensions();

  useEffect(() => {
    setLandingPageData(JsonData);
    console.log(JsonData)
    AOS.init({
      duration : 2000
    });
    // a
  }, []);

  return (
    <Router>
    <div >
    <div style={{position:"fixed",top:width<500?"90%":"87%",left:width<500?"85%":"93%",}} >
      <ReactWhatsapp className="" style={{border:"none",backgroundColor:"transparent",color:"none", cursor:"pointer",}} number={"+923087894109"} message=" Hello, I’d like to know more about Eat Well’s meal services. Please share the details. " >
      <i className="fa fa-whatsapp shrink-icon" style={{ color: "#25D366", fontSize: "60px" ,position:"fixed"}}> </i> 
           
          
         </ReactWhatsapp>

      </div>
    <Switch>
      <Route exact path="/">
     <>    
     <Navigation data={{Home:"#Home"}} />

       <Header data={landingPageData.Header} color={landingPageData.color} />
      {/* // hide for some time 15-june-2024 */}

      {/* <Features data={landingPageData.Features} /> */}
      {/* <About data={landingPageData.About} /> */}
      {/* <Services data={landingPageData.Services} /> */}
      <Gallery data={landingPageData.Gallery} color={landingPageData.color} />
     
      <Testimonials data={landingPageData.Testimonials} color={landingPageData.color} />
      {/* <Team data={landingPageData.Team} /> */}
      </>
      </Route>
      
      <Route path="/ExectiveMenu" component={ExectiveMenu} />
      <Route path="/Subscription" component={Subscription} />

     
      </Switch>
      <Contact data={landingPageData.Contact}  color={landingPageData.color}/>

    </div>
    
    </Router>
  );
};

export default App;
